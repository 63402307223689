<div class="row mt-3">
  <div class="col-md-8 mx-auto">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">Registro de Usuario</h1>
        <form [formGroup]="registerForm" (ngSubmit)="onRegister()">
          <div class="row">

            <div class="container p-3">
              <mat-horizontal-stepper [linear]="isLinear" #stepper>

                <mat-step [stepControl]="firstFormGroup">
                  <ng-template matStepLabel>Verificá tu CUIT</ng-template>

                  <div class="row">
                    <div class="col-sm-6 p-2">

                      <div class="form-group">
                        <label for="cuit">CUIT:</label>
                        <input type="text" placeholder="99-99999999-9" class="form-control" formControlName="cuit"
                          (keyup)="traerClienteByCuit()" />
                      </div>
                      <small class="invalid"
                        *ngIf="registerForm.controls.cuit.errors?.pattern && registerForm.controls.cuit.touched">
                        Formato de CUIT incorrecto.
                      </small>
                    </div>

                    <div class="col-sm-6   p-2">
                      <label for="cuit">&nbsp;</label>
                      <div *ngIf="!bottonHabilitado">
                        <button class="btn btn-primary btn-lg btn-block" mat-button disabled="disabled">
                          Verificar
                        </button>
                      </div>
                      <div *ngIf="bottonHabilitado">
                        <button class="btn btn-primary btn-lg btn-block" mat-button matStepperNext>
                          Verificar
                        </button>
                      </div>
                    </div>

                    <div class="mt-2">
                      <p><b><span>ATENCION !!</span></b>
                        Para poder registrarte debes ser cliente de <b>JUNAR S.A.</b> juntas MEYRO, para hacerte
                        cliente,
                        llámanos al <b> ( 54-11 ) 3221-9960 </b> o envíanos un mail a <b>ventas"&#64;"juntasmeyro.com.ar</b> y
                        a la
                        brevedad nos
                        comunicaremos.
                        <br><br>
                        <b>Muchas Gracias</b>
                      </p>
                    </div>

                  </div>
                </mat-step>

                <mat-step [stepControl]="secondFormGroup">
                  <ng-template matStepLabel>Completá Tus Datos</ng-template>

                  <div *ngIf="cuitCorrecto">

                    <h2>Numero de Cuenta : {{ cliente.idCliente }}</h2>
                    <div class="row">

                      <!-- ////////////////////////////  RAZON SOCIAL //////////////////////////////////////////////// -->
                      <div class="col-sm-6 p-2">
                        <label for="year">Razon Social :</label>
                        <input type="text" class="form-control" formControlName="razonSocial"/>
                      </div>

                      <small class="invalid"
                        *ngIf="registerForm.controls.razonSocial.errors?.required && registerForm.controls.razonSocial.touched">
                        La razón social es requerida.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.razonSocial.errors?.minlength && registerForm.controls.razonSocial.touched">
                        La razón social debe tener al menos tres caracteres.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.razonSocial.errors?.maxlength && registerForm.controls.razonSocial.touched">
                        La razón social puede tener cien caracteres maximo.
                      </small>

                      <!-- ////////////////////////////  CALLE Y NRO //////////////////////////////////////////////// -->
                      <div class="col-sm-6 p-2">
                        <label for="year">Calle y Numero :</label>
                        <input type="text" class="form-control" formControlName="calleAndNumero" />
                      </div>

                      <small class="invalid"
                        *ngIf="registerForm.controls.calleAndNumero.errors?.required && registerForm.controls.calleAndNumero.touched">
                        La Calle y Numero es requerida.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.calleAndNumero.errors?.minlength && registerForm.controls.calleAndNumero.touched">
                        La Calle y Numero debe tener al menos tres caracteres.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.calleAndNumero.errors?.maxlength && registerForm.controls.calleAndNumero.touched">
                        La Calle y Numero puede tener cien caracteres maximo.
                      </small>

                      <!-- ////////////////////////////  LOCALIDAD //////////////////////////////////////////////// -->
                      <div class="col-sm-9 p-2">
                        <label for="year">Localidad :</label>
                        <input type="text" class="form-control" formControlName="localidad" />
                      </div>

                      <small class="invalid"
                        *ngIf="registerForm.controls.localidad.errors?.required && registerForm.controls.localidad.touched">
                        La localidad es requerida.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.localidad.errors?.minlength && registerForm.controls.localidad.touched">
                        La localidad debe tener al menos tres caracteres.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.localidad.errors?.maxlength && registerForm.controls.localidad.touched">
                        La localidad puede tener cien caracteres maximo.
                      </small>

                      <!-- ////////////////////////////  CP //////////////////////////////////////////////// -->
                      <div class="col-sm-3 p-2">
                        <label for="year">Codigo Postal :</label>
                        <input type="text" class="form-control" formControlName="cp" />
                      </div>

                      <small class="invalid"
                        *ngIf="registerForm.controls.cp.errors?.required && registerForm.controls.cp.touched">
                        El codigo postal es requerido.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.cp.errors?.minlength && registerForm.controls.cp.touched">
                        El codigo postal debe tener al menos cuatro caracteres.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.cp.errors?.maxlength && registerForm.controls.cp.touched">
                        El codigo postal puede tener ocho caracteres maximo.
                      </small>

                      <!-- ////////////////////////////  PROVINCIA //////////////////////////////////////////////// -->
                      <div class="col-sm-7 p-2">
                        <label for="year">Provincia :</label>
                        <input type="text" class="form-control" formControlName="provincia" />
                      </div>

                      <small class="invalid"
                        *ngIf="registerForm.controls.provincia.errors?.required && registerForm.controls.provincia.touched">
                        La provincia es requerida.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.provincia.errors?.minlength && registerForm.controls.provincia.touched">
                        La provincia debe tener al menos dos caracteres.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.provincia.errors?.maxlength && registerForm.controls.provincia.touched">
                        La provincia puede tener cincuenta caracteres maximo.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.provincia.errors?.pattern && registerForm.controls.provincia.touched">
                        La provincia puede contener solo letras.
                      </small>

                      <!-- ////////////////////////////  TELEFONO //////////////////////////////////////////////// -->
                      <div class="col-sm-5 p-2">
                        <label for="year">Telefono :</label>
                        <input type="text" class="form-control" formControlName="telefonoLinea" />
                      </div>

                      <small class="invalid"
                        *ngIf="registerForm.controls.telefonoLinea.errors?.required && registerForm.controls.telefonoLinea.touched">
                        El telefono es requerido.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.telefonoLinea.errors?.minlength && registerForm.controls.telefonoLinea.touched">
                        El telefono debe tener al menos dos caracteres.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.telefonoLinea.errors?.maxlength && registerForm.controls.telefonoLinea.touched">
                        El telefono puede tener cincuenta caracteres maximo.
                      </small>

                      <!--
                    <small class="invalid"
                    *ngIf="registerForm.controls.telefonoLinea.errors?.pattern && registerForm.controls.telefonoLinea.touched">
                    Ingrese un nro. de telefono valido.
                  </small>
                  -->

                      <!-- ////////////////////////////  COMPRADOR //////////////////////////////////////////////// -->
                      <div class="col-sm-6 p-2">
                        <label for="year">Responsable de Compras :</label>
                        <input type="text" class="form-control" formControlName="comprador" />
                      </div>

                      <small class="invalid"
                        *ngIf="registerForm.controls.comprador.errors?.required && registerForm.controls.comprador.touched">
                        El Responsable de Compras es requerida.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.comprador.errors?.minlength && registerForm.controls.comprador.touched">
                        El Responsable de Compras debe tener al menos dos caracteres.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.comprador.errors?.maxlength && registerForm.controls.comprador.touched">
                        El Responsable de Compras puede tener cien caracteres maximo.
                      </small>

                      <!-- ////////////////////////////  CELULAR //////////////////////////////////////////////// -->
                      <div class="col-sm-6 p-2">
                        <label for="year">Telefono Celular :</label>
                        <input type="text" class="form-control" formControlName="telefonoCelular" />
                      </div>

                      <small class="invalid"
                        *ngIf="registerForm.controls.telefonoCelular.errors?.required && registerForm.controls.telefonoCelular.touched">
                        El telefono celular es requerido.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.telefonoCelular.errors?.minlength && registerForm.controls.telefonoCelular.touched">
                        El telefono celular debe tener al menos dos caracteres.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.telefonoCelular.errors?.maxlength && registerForm.controls.telefonoCelular.touched">
                        El telefono celular puede tener cien caracteres maximo.
                      </small>

                      <!-- ////////////////////////////  MAIL //////////////////////////////////////////////// -->
                      <div class="col-sm-6 p-2">
                        <label for="year">email :</label>
                        <input type="text" class="form-control" formControlName="email" />
                      </div>

                      <small class="invalid"
                        *ngIf="registerForm.controls.email.errors?.required && registerForm.controls.email.touched">
                        El e-mail es requerido.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.email.errors?.minlength && registerForm.controls.email.touched">
                        El e-mail debe tener al menos dos caracteres.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.email.errors?.maxlength && registerForm.controls.email.touched">
                        El e-mail puede tener cien caracteres maximo.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.email.errors?.mail && registerForm.controls.email.touched">
                        Debe ingresar un e-mail valido.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.email.errors?.pattern && registerForm.controls.email.touched">
                        Debe ingresar un e-mail valido.
                      </small>
                      <!-- ////////////////////////////  CONTRASEÑA //////////////////////////////////////////////// -->
                      <div class="col-sm-6 mt-2">
                        <label for="year">Contraseña :</label>
                        <input type="text" class="form-control" formControlName="password" />
                      </div>

                      <small class="invalid"
                        *ngIf="registerForm.controls.password.errors?.required && registerForm.controls.password.touched">
                        La contraseña es requerida.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.password.errors?.minlength && registerForm.controls.password.touched">
                        La contraseña debe tener al menos seis caracteres.
                      </small>

                      <small class="invalid"
                        *ngIf="registerForm.controls.password.errors?.maxlength && registerForm.controls.password.touched">
                        La contraseña puede tener veinte caracteres maximo.
                      </small>

                    </div>

                    <button mat-button matStepperNext type="submit" class="btn btn-primary btn-lg btn-block" [disabled]="!registerForm.valid">
                      Creá tu cuenta
                    </button>

                  </div>

                  <div *ngIf="!cuitCorrecto">
                    <div class="mt-2">
                      <p><b><span>ATENCION !!</span></b>
                        Para poder registrarte debes ser cliente de <b>JUNAR S.A.</b> juntas MEYRO, para hacerte
                        cliente,
                        llámanos al <b> ( 54-11 ) 3221-9960 </b> o envíanos un mail a <b>ventas"&#64;"juntasmeyro.com.ar</b> y
                        a la
                        brevedad nos
                        comunicaremos.
                        <br><br>
                        <b>Muchas Gracias</b>
                      </p>
                    </div>
                  </div>

                </mat-step>
              </mat-horizontal-stepper>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>